/* WhatsApp Button Styles */
.call-button {
  position: fixed;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  z-index: 1000;
}

.call-button img {
  width: 100%;
  height: 100%;
  object-fit: contain;
}